const proficiencies = [
  {
    proficiencyArea: "Front-end",
    proficiencyStatement:
      "Architect and develop user-facing websites and applications using Figma, NPM, React, Vue.js, HTML, Javascript (ES6+), JSX, CSS/SASS, and Tailwind CSS. Emphasis on user-friendly interfaces, optimizing usability and ensuring compatibility with various browsers and screen sizes.",
  },
  {
    proficiencyArea: "Back-end/General Development",
    proficiencyStatement:
      "Build scalable, secure databases and applications. Integrate back-end and front-end code using MySQL, PHP, Python, Java, C++, Node.js. —Meld APIs and frameworks to front-end code of applications and websites to add functionality.",
  },
  {
    proficiencyArea: "Web Performance Optimization",
    proficiencyStatement:
      "Uncover issues using Google Pagespeed Insights/YSlow to decrease page load time. Migrate websites or applications over to a Content Delivery Network (CDN) and optimize modules and media. Utilize unit testing to verify absolute reliability and efficient code.",
  },
  {
    proficiencyArea: "Web Analytics",
    proficiencyStatement:
      "Find valuable insights into webpages using Google Analytics. Measure performance and enhance website design to reach objectives. Better understand users to better curate content and layout of pages.",
  },
  {
    proficiencyArea: "Data Analysis",
    proficiencyStatement:
      "Collect, wrangle, cleanse, explore, and find significant data trends using Python within industry-standard technologies like Jupyter Notebook, Google Collab, or PyCharm. Leverage and push Python's data science capabilities by using packages like Matploptib and Pandas.",
  },
  {
    proficiencyArea: "Machine Learning",
    proficiencyStatement:
      "Understand the data structure and fit that data into models using Python, its packages, and TensorFlow that can be understood and utilized to benefit systems while documenting and minimizing biases. Build comprehensive models that improve as datasets grow and mature.",
  },
];

export default function TechnicalProficiencies() {
  return (
    <div className="font-sans py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-8 sm:pt-0 ">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-3xl font-medium text-Custom-Cultured font-mono font-bold text-left">
            Technical Proficiencies
          </h2>
          <div className="pt-16 font-mono">
            <dl className="grid gap-x-4 gap-y-12 md:grid-cols-3 grid-cols-2 gap-4 text-Custom-Cultured">
              {proficiencies.map((proficiencyType, index) => (
                <div key={index}>
                  <div className="leading-6 text-Custom-Cultured mb-3 font-bold">
                    {proficiencyType.proficiencyArea}
                  </div>
                  <div className="pt-2 font-mono leading-relaxed opacity-75">
                    {proficiencyType.proficiencyStatement}
                  </div>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}
