import "../Styles/blobz.min.css";

const Hero = () => {
    const Tailwind_CSSLink = "https://tailwindcss.com/";
    const ReactLink = "https://reactjs.org/";

    return (
        <div className="bg-none">
            {/* START OF CONTAINER */}
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-4 sm:pt-0">
                <div className="max-w-4xl mx-auto">
                    {/* END OF CONTAINER */}
                    <div className="sm:hidden px-4 tracking-tight grid h-screen place-items-center text-center font-mono sm:font-normal text-Custom-Cultured text-4xl">
                        <div>
                        I'M MAX MATUSKA
                        SCROLL
                            <span className="mx-3.5 inline-block whitespace-nowrap rounded-full px-4 bg-Custom-Blob-Color text-Custom-Rich-Black-FOGRA-39 font-light">
                                <svg className="py-1" width="26.5" height="26.5" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="27.6309" width="6.26295" height="57.1034" fill="currentColor"/>
                                <rect x="35.4287" y="57.5713" width="6.26295" height="43.8406" transform="rotate(135 35.4287 57.5713)" fill="currentColor"/>
                                <rect x="31" y="62" width="6.26295" height="43.8406" transform="rotate(-135 31 62)" fill="currentColor"/>
                                </svg>
                            </span>
                        FOR MORE
                        </div>
                    </div>
                    <div className="hidden sm:grid lg:visible sm:py-6 grid sm:place-items-center h-screen">
                        <div className="m-auto">
                            <div
                                className="
                  font-mono
                  sm:font-normal
                  text-Custom-Cultured
                  text-left
                  pb-6
                  sm:pb-8
                  md:pb-2
                  text-4xl
                  font-bold
                  sm:font-normal
                  lg:text-5xl
                  z-100
                  "
                            >
                                I'm Max Matuska
                            </div>
                            <div
                                className="
                opacity-95
                    text-Custom-Cultured
                    text-left
                    text-3xl
                    lg:text-5xl
                    md:font-bold
                    font-mono
                    pr-6
                    sm:font-normal
                    font-bold
                "
                            >
                <span className="leading-snug">
                  A developer based in Rochester, New York. Currently seeking a
                  co-op/internship or entry-level position (remote or
                  in-person).
                </span>
                            </div>
                            <div className="text-Custom-Cultured opacity-30 pt-3 text-sm lg:text-base font-mono">
                                Content powered by{" "}
                                <a className={"underline"} href={ReactLink}>
                                    React
                                </a>{" "}
                                and{" "}
                                <a className={"underline"} href={Tailwind_CSSLink}>
                                    Tailwind CSS
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hero;
