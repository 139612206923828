const Fade = () => {
  return (
    <div
      className="w-full h-16 inset-x-0 bottom-0 fixed pointer-events-none"
      style={{
        background:
          "linear-gradient(0deg, rgba(10,10,10,1) 0%, rgba(10,10,10,0) 95%)",
      }}
    />
  );
};

export default Fade;
