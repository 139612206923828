const learningProjects = [
    {
        ping: true,
        learningProjectTitle: "Recipe Directory (Web Application)",
        learningProjectDescription:
            "A React application that allows the user to browse, search," +
            " and add recipes inside a JSON database." +
            "This project uses HTML, CSS, SASS, JSX, and JavaScript. ",
        learningProjectLastUpdated: "05-30-22",
        link: "https://github.com/matuskam/recipe-directory-app"
    },
    {
        ping: true,
        learningProjectTitle: "Concentration Memory Game Clone (Web Application)",
        learningProjectDescription:
            "A remake of the memory card game 'Concentration' using React, " +
            "Tailwind CSS, CSS, JSX, and JavaScript. " +
            "Match the card pairs in as few moves as you can.",
        learningProjectLastUpdated: "05-18-22",
        link: "https://github.com/matuskam/concentration-app"
    },
    {
        ping: false,
        learningProjectTitle: "Bitcoin Price Prediction",
        learningProjectDescription: "This project is the final iteration of our capstone assignment at" +
            " the end of Google's Applied Machine Learning Intensive (AMLI). AMLI is a course designed" +
            " with numerous computer science topics helping students to become qualified for positions" +
            " like entry-level machine learning (ML) Specialists. This project consisted of a machine" +
            " learning model that would predict when to buy and sell the cryptocurrency Bitcoin, depending" +
            " on inputs from multiple data sources. Our project explored long short-term memory networks (LSTM)," +
            " echo state networks (ESN), data collection techniques, and data processing pipelines. Our team" +
            " designed specialized scripts that scraped data from Twitter and Reddit and processed it through" +
            " tailored sentiment analysis. Cleaned data was fed into ML models that achieved an accuracy score" +
            " ranging from 51% to 53%. We worked with Python, JSON, TensorFlow, and Jupyter Notebook.",
        learningProjectLastUpdated: "01-01-2019",
        link: "https://github.com/matuskam/AMLI_FinalProject"
    },

];

const SelectedLearningProjects = () => {
    return (
        <div className="bg-Custom-Rich-Black-FOGRA-39 font-sans py-16">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-8 sm:pt-0">
                <div className="max-w-4xl mx-auto">
                    <h2 className="text-3xl font-bold text-Custom-Cultured font-mono">
                        Select Learning Projects
                    </h2>

                    <div className="pt-16 font-mono">
                        <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-1 md:gap-x-20 md:gap-y-12">
                            {learningProjects.map((learningProjects, index) => (
                                <div key={index}>
                                    <a
                                        id="learningProjects"
                                        className="leading-6 text-Custom-Cultured mb-3"
                                        href={learningProjects.link}
                                    >
                                        <div className="leading-6 text-Custom-Cultured mb-3">
                      <span className="py-2 font-mono">
                        <span className="font-bold">
                          {learningProjects.learningProjectTitle}
                        </span>

                          <span
                              className="my-3 sm:my-0 sm:ml-3 px-2 py-0.5 font-normal opacity-60 relative sm:inline-block table"
                              style={{backgroundColor: "#BB342F"}}
                          >
                            {learningProjects.ping === true &&
                            <span>
                            <span
                                className="absolute top-0 right-0 -mr-1.5 -mt-1.5 w-3.5 h-3.5 rounded-full animate-ping"
                                style={{backgroundColor: "#e18c89"}}
                            />
                            <span
                                className="absolute top-0 right-0 -mr-1.5 -mt-1.5 w-3.5 h-3.5 rounded-full"
                                style={{backgroundColor: "#e18c89"}}
                            />
                              </span>
                            }
                              Last push/update:
                              {" " + learningProjects.learningProjectLastUpdated}
                        </span>
                      </span>
                                            <div className="pt-2 font-mono leading-relaxed opacity-75">
                                                {learningProjects.learningProjectDescription}
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </dl>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelectedLearningProjects;
