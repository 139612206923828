import "../Styles/blobz.min.css";

const MorphBlob = () => {
  return (
    <div
      className="hidden sm:visible w-full overflow-hidden sm:w-2/5 left-1/2 transform -translate-x-1/3 translate-y-40 md:translate-y-1/2 lg:translate-y-1/4 xl:translate-y-0 absolute opacity-50 sm:visible"
      style={{ zIndex: -1}}
    >
      <div className="tk-blob" style={{ fill: "#9984D4", zIndex: 100 }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 341.4 374.7">
          <path d="M309.9 70.6c37.8 52.7 39.8 128.7 15.4 184.1-24.3 55.4-75 90.1-125.4 107.4-50.4 17.4-100.4 17.4-136.2-3.3-35.7-20.7-57.2-62-62.4-102.1-5.2-40.2 5.8-79 29.1-128.3C53.6 79.1 89.1 19.3 143.7 4.1 198.3-11.2 272 18 309.9 70.6z" />
        </svg>
      </div>
    </div>
  );
};

export default MorphBlob;
