import Hero from "../Components/Hero";
import SelectedLearningProjects from "../Components/SelectedLearningProjects";
import Technologies from "../Components/Technologies";
import MainFooter from "../Components/Footer";
import MorphBlob from "../Components/MorphBlob";
import Fade from "../Components/Fade";

const Home = () => {
  return (
    <div
      className="home bg-Custom-Rich-Black-FOGRA-39 absolute"
      style={{ zIndex: -1 }}
    >
      <MorphBlob />
      <Hero />
      <Technologies />
      <SelectedLearningProjects />
      <MainFooter />
      <Fade />
    </div>
  );
};

export default Home;
