import "./index.css";
import { BrowserRouter, Route, Link, Routes} from 'react-router-dom'

//Import Pages
import Home from "./Pages/Home";
import Contact from "./Pages/Contact"

function App() {
  return (
    <div className="App bg-Custom-Rich-Black-FOGRA-39">
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/contact" element={<Contact />} />
            </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
