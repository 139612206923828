import "../Styles/li.css";

const aboutMePoints = [
  {
    aboutMePoint: "Graduate from SUNY Buffalo State College",
  },
  {
    aboutMePoint:
      "Currently hold a Bachelor of Science degree in Computer Information Systems",
  },
  {
    aboutMePoint: "Graduated with Honors Magna Cum Laude",
  },
  {
    aboutMePoint:
      "Awarded 'Excellence in Computer Information Systems' for academic and student merit",
  },
  {
    aboutMePoint:
        "One of four selected to build and optimize the SUNY Buffalo State Computer Information Systems website",
  },
];

const MainFooter = () => {
  return (
    <div className="bg-Custom-Rich-Black-FOGRA-39">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-48 sm:py-16 mb-16">
        <div className="block sm:mb-0 sm:mr-8">
          <div className="pb-6">
            <span className="text-3xl text-Custom-Cultured font-mono font-bold">
              About
            </span>
          </div>
        </div>
        <div className="block text-Custom-Cultured font-mono leading-relaxed text-md grid sm:grid-cols-2">
          {aboutMePoints.map((aboutMePoints, index) => (
            <ul
              className="ml-6 mb-4 list-disc leading-6 text-Custom-Cultured"
              key={index}
            >
              <li className="opacity-75 pl-2">{aboutMePoints.aboutMePoint}</li>
            </ul>
          ))}
        </div>
      </div>
      <div className="font-mono text-Custom-Cultured text-center opacity-30 mb-16">
        <div className="mb-2">Designed and built by Max Matuska</div>
        <span className="inline-block">
          <a className="w-1/2 inline-block" href="https://github.com/matuskam">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 mx-1.5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M2 5a2 2 0 012-2h12a2 2 0 012 2v10a2 2 0 01-2 2H4a2 2 0 01-2-2V5zm3.293 1.293a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 01-1.414-1.414L7.586 10 5.293 7.707a1 1 0 010-1.414zM11 12a1 1 0 100 2h3a1 1 0 100-2h-3z"
                clip-rule="evenodd"
              />
            </svg>
          </a>
          <a
            className="w-1/2 inline-block"
            href="https://meritpages.com/Max-Matuska/4281108"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 mx-1.5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M10.394 2.08a1 1 0 00-.788 0l-7 3a1 1 0 000 1.84L5.25 8.051a.999.999 0 01.356-.257l4-1.714a1 1 0 11.788 1.838L7.667 9.088l1.94.831a1 1 0 00.787 0l7-3a1 1 0 000-1.838l-7-3zM3.31 9.397L5 10.12v4.102a8.969 8.969 0 00-1.05-.174 1 1 0 01-.89-.89 11.115 11.115 0 01.25-3.762zM9.3 16.573A9.026 9.026 0 007 14.935v-3.957l1.818.78a3 3 0 002.364 0l5.508-2.361a11.026 11.026 0 01.25 3.762 1 1 0 01-.89.89 8.968 8.968 0 00-5.35 2.524 1 1 0 01-1.4 0zM6 18a1 1 0 001-1v-2.065a8.935 8.935 0 00-2-.712V17a1 1 0 001 1z" />
            </svg>
          </a>
        </span>
      </div>
    </div>
  );
};

export default MainFooter;
